"use client";

import { Stack } from "@mui/material";

import { FcWithChildren } from "common/components/types";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { useLayoutStore } from "common/state/useLayoutStore";

const TopicMainContent: FcWithChildren = ({ children, hideNav = false }) => {
  const isMobile = useIsMobile();
  const { desktopNavIsOpen, desktopContainerWidth, desktopTransform } = useLayoutStore(
    (state) => state
  );
  const baseSx = {
    flex: 1,
    transition:
      "min-width .25s ease-in-out, max-width .25s ease-in-out, transform .25s ease-in-out",
    maxWidth: isMobile || hideNav ? "100%" : desktopContainerWidth,
    overflow: "clip",
    position: "relative"
  };
  const sx =
    desktopNavIsOpen && !hideNav
      ? baseSx
      : {
          ...baseSx,
          minWidth: { xs: "100vw", md: desktopContainerWidth },
          transform: { xs: "none", md: desktopTransform }
        };
  return <Stack sx={sx}>{children}</Stack>;
};

export default TopicMainContent;
